export const NAVBAR_CONFIG = [
  {
    disp: "Home",
    url: "/",
  },
  {
    disp: "About",
    url: "/about",
  },
  {
    disp: "Resume",
    url: "/resume",
  },
  {
    disp: "Expertise",
    url: "/expertise",
  },
  // {
  //   disp: "Portfolio",
  //   url: "/portfolio",
  // },
  {
    disp: "Contact",
    url: "/contact",
  },
];
